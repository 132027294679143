import type { ModuleMediaFeatureFieldsFragment } from "@/lib/contentful/__generated/graphql.types";
import { MediaFeature } from "@/components/media-feature";

export type ModuleMediaFeatureProps = ModuleMediaFeatureFieldsFragment;

export const ModuleMediaFeature = (props: ModuleMediaFeatureProps) => {
    const { title, text, mediaAsset, link, linkLabel } = props;

    return (
        <MediaFeature
            link={link}
            title={title}
            text={text}
            mediaAsset={mediaAsset}
            linkLabel={linkLabel}
        />
    );
};
