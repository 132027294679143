import { useContentfulLiveUpdates } from "@contentful/live-preview/react";
import type { CoreGraphqlEntryProps } from "@/types/page";
import { useModuleMediaFeatureQuery } from "@/components/contentful/module-media-feature/__generated/module-media-feature.contentful.generated";
import { ModuleMediaFeature } from "@/components/contentful/module-media-feature/module-media-feature";

export type ModuleMediaFeatureGraphqlProps = CoreGraphqlEntryProps;

export const ModuleMediaFeatureGraphql = ({
    id,
    locale,
    preview,
}: ModuleMediaFeatureGraphqlProps) => {
    const { data, isLoading } = useModuleMediaFeatureQuery(
        {
            id,
            locale,
            preview,
        },
        {
            ...(!preview && { staleTime: Infinity }),
            refetchOnWindowFocus: false,
        }
    );

    const moduleMediaFeature = useContentfulLiveUpdates(data?.moduleMediaFeature);

    if (isLoading || !moduleMediaFeature) {
        return null;
    }

    return <ModuleMediaFeature {...moduleMediaFeature} />;
};
