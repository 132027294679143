import * as Types from "../../../../lib/contentful/__generated/graphql.types";

import {
    ComponentReferenceFields_Car_Fragment,
    ComponentReferenceFields_ContentTag_Fragment,
    ComponentReferenceFields_Driver_Fragment,
    ComponentReferenceFields_ExternalLink_Fragment,
    ComponentReferenceFields_Footer_Fragment,
    ComponentReferenceFields_LanguageSelectorItem_Fragment,
    ComponentReferenceFields_MainNavigation_Fragment,
    ComponentReferenceFields_Microcopy_Fragment,
    ComponentReferenceFields_MicrocopySet_Fragment,
    ComponentReferenceFields_ModalLink_Fragment,
    ComponentReferenceFields_ModuleAccordion_Fragment,
    ComponentReferenceFields_ModuleAudioPlayer_Fragment,
    ComponentReferenceFields_ModuleCarTechSpecs_Fragment,
    ComponentReferenceFields_ModuleCarousel_Fragment,
    ComponentReferenceFields_ModuleCircuit_Fragment,
    ComponentReferenceFields_ModuleDriverList_Fragment,
    ComponentReferenceFields_ModuleGallery_Fragment,
    ComponentReferenceFields_ModuleIframe_Fragment,
    ComponentReferenceFields_ModuleImage_Fragment,
    ComponentReferenceFields_ModuleListenToTheEngine_Fragment,
    ComponentReferenceFields_ModuleMediaFeature_Fragment,
    ComponentReferenceFields_ModulePageLinkTile_Fragment,
    ComponentReferenceFields_ModuleQuickLinks_Fragment,
    ComponentReferenceFields_ModuleQuote_Fragment,
    ComponentReferenceFields_ModuleRichText_Fragment,
    ComponentReferenceFields_ModuleSideBySide_Fragment,
    ComponentReferenceFields_ModuleSpacer_Fragment,
    ComponentReferenceFields_ModuleSplitLayout_Fragment,
    ComponentReferenceFields_ModuleTable_Fragment,
    ComponentReferenceFields_ModuleVideo_Fragment,
    ComponentReferenceFields_Navigation_Fragment,
    ComponentReferenceFields_PageArticle_Fragment,
    ComponentReferenceFields_PageBasic_Fragment,
    ComponentReferenceFields_PageCar_Fragment,
    ComponentReferenceFields_PageCategory_Fragment,
    ComponentReferenceFields_PageDriver_Fragment,
    ComponentReferenceFields_PageHomepage_Fragment,
    ComponentReferenceFields_PageRaceEvent_Fragment,
    ComponentReferenceFields_PageRaceSeries_Fragment,
    ComponentReferenceFields_PageSearch_Fragment,
    ComponentReferenceFields_PageTeam_Fragment,
    ComponentReferenceFields_PartAccordionItem_Fragment,
    ComponentReferenceFields_PartCircuitHotspot_Fragment,
    ComponentReferenceFields_PartMainNavigationAccordion_Fragment,
    ComponentReferenceFields_PartMainNavigationItem_Fragment,
    ComponentReferenceFields_Partner_Fragment,
    ComponentReferenceFields_PartnerSet_Fragment,
    ComponentReferenceFields_SeoMetadata_Fragment,
    ComponentReferenceFields_SiteSettings_Fragment,
    ComponentReferenceFields_Team_Fragment,
} from "../../../../lib/contentful/__generated/component-map.contentful.generated";
import { ExternalLinkFieldsFragment } from "../../navigation/__generated/navigation.contentful.generated";
import { PageArticleLinkToFieldsFragment } from "../../page-article/__generated/page-article.contentful.generated";
import { ContentTagFieldsFragment } from "../../../../lib/contentful/content-tag/__generated/content-tag.contentful.generated";
import { PageBasicLinkToFieldsFragment } from "../../page-basic/__generated/page-basic.contentful.generated";
import { PageCarLinkToFieldsFragment } from "../../page-car/__generated/page-car.contentful.generated";
import {
    PartnerSetFieldsFragment,
    PartnerFieldsFragment,
} from "../../partner-set/__generated/partner-set.contentful.generated";
import { PageCategoryLinkToFieldsFragment } from "../../page-category/__generated/page-category.contentful.generated";
import { PageDriverLinkToFieldsFragment } from "../../page-driver/__generated/page-driver.contentful.generated";
import { PageHomepageLinkToFieldsFragment } from "../../page-homepage/__generated/page-homepage.contentful.generated";
import {
    PageRaceEventFieldsFragment,
    PageRaceEventLinkToFieldsFragment,
} from "../../page-race-event/__generated/page-race-event.contentful.generated";
import { PageTeamLinkToFieldsFragment } from "../../page-team/__generated/page-team.contentful.generated";
import { TeamFieldsFragment } from "../../team/__generated/team.contentful.generated";
import { ComponentReferenceFieldsFragmentDoc } from "../../../../lib/contentful/__generated/component-map.contentful.generated";
import { ExternalLinkFieldsFragmentDoc } from "../../navigation/__generated/navigation.contentful.generated";
import { PageArticleLinkToFieldsFragmentDoc } from "../../page-article/__generated/page-article.contentful.generated";
import { ContentTagFieldsFragmentDoc } from "../../../../lib/contentful/content-tag/__generated/content-tag.contentful.generated";
import { PageBasicLinkToFieldsFragmentDoc } from "../../page-basic/__generated/page-basic.contentful.generated";
import { PageCarLinkToFieldsFragmentDoc } from "../../page-car/__generated/page-car.contentful.generated";
import {
    PartnerSetFieldsFragmentDoc,
    PartnerFieldsFragmentDoc,
} from "../../partner-set/__generated/partner-set.contentful.generated";
import { PageCategoryLinkToFieldsFragmentDoc } from "../../page-category/__generated/page-category.contentful.generated";
import { PageDriverLinkToFieldsFragmentDoc } from "../../page-driver/__generated/page-driver.contentful.generated";
import { PageHomepageLinkToFieldsFragmentDoc } from "../../page-homepage/__generated/page-homepage.contentful.generated";
import {
    PageRaceEventFieldsFragmentDoc,
    PageRaceEventLinkToFieldsFragmentDoc,
} from "../../page-race-event/__generated/page-race-event.contentful.generated";
import { PageTeamLinkToFieldsFragmentDoc } from "../../page-team/__generated/page-team.contentful.generated";
import { TeamFieldsFragmentDoc } from "../../team/__generated/team.contentful.generated";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { customFetcher } from "@/lib/contentful/fetch-config";
export type ModuleMediaFeatureFieldsFragment = {
    __typename?: "ModuleMediaFeature";
    title?: string | null;
    text?: string | null;
    mediaAsset?: any | null;
    linkLabel?: string | null;
    link?:
        | ({ __typename?: "ExternalLink" } & ExternalLinkFieldsFragment &
              ComponentReferenceFields_ExternalLink_Fragment)
        | ({ __typename?: "PageArticle" } & PageArticleLinkToFieldsFragment &
              ComponentReferenceFields_PageArticle_Fragment)
        | ({ __typename?: "PageBasic" } & PageBasicLinkToFieldsFragment &
              ComponentReferenceFields_PageBasic_Fragment)
        | ({ __typename?: "PageCar" } & PageCarLinkToFieldsFragment &
              ComponentReferenceFields_PageCar_Fragment)
        | ({ __typename?: "PageCategory" } & PageCategoryLinkToFieldsFragment &
              ComponentReferenceFields_PageCategory_Fragment)
        | ({ __typename?: "PageDriver" } & PageDriverLinkToFieldsFragment &
              ComponentReferenceFields_PageDriver_Fragment)
        | ({ __typename?: "PageHomepage" } & PageHomepageLinkToFieldsFragment &
              ComponentReferenceFields_PageHomepage_Fragment)
        | ({ __typename?: "PageRaceEvent" } & PageRaceEventFieldsFragment &
              ComponentReferenceFields_PageRaceEvent_Fragment)
        | ({ __typename?: "PageTeam" } & PageTeamLinkToFieldsFragment &
              ComponentReferenceFields_PageTeam_Fragment)
        | null;
} & ComponentReferenceFields_ModuleMediaFeature_Fragment;

export type ModuleMediaFeatureQueryVariables = Types.Exact<{
    locale: Types.Scalars["String"]["input"];
    preview: Types.Scalars["Boolean"]["input"];
    id: Types.Scalars["String"]["input"];
}>;

export type ModuleMediaFeatureQuery = {
    __typename?: "Query";
    moduleMediaFeature?:
        | ({ __typename?: "ModuleMediaFeature" } & ModuleMediaFeatureFieldsFragment)
        | null;
};

export const ModuleMediaFeatureFieldsFragmentDoc = `
    fragment ModuleMediaFeatureFields on ModuleMediaFeature {
  ...ComponentReferenceFields
  title
  text
  mediaAsset
  linkLabel
  link {
    ...ComponentReferenceFields
    ... on ExternalLink {
      ...ExternalLinkFields
    }
    ... on PageArticle {
      ...PageArticleLinkToFields
    }
    ... on PageBasic {
      ...PageBasicLinkToFields
    }
    ... on PageCar {
      ...PageCarLinkToFields
    }
    ... on PageCategory {
      ...PageCategoryLinkToFields
    }
    ... on PageDriver {
      ...PageDriverLinkToFields
    }
    ... on PageHomepage {
      ...PageHomepageLinkToFields
    }
    ... on PageRaceEvent {
      ...PageRaceEventFields
    }
    ... on PageTeam {
      ...PageTeamLinkToFields
    }
  }
}
    `;
export const ModuleMediaFeatureDocument = `
    query ModuleMediaFeature($locale: String!, $preview: Boolean!, $id: String!) {
  moduleMediaFeature(id: $id, locale: $locale, preview: $preview) {
    ...ModuleMediaFeatureFields
  }
}
    ${ModuleMediaFeatureFieldsFragmentDoc}
${ComponentReferenceFieldsFragmentDoc}
${ExternalLinkFieldsFragmentDoc}
${PageArticleLinkToFieldsFragmentDoc}
${ContentTagFieldsFragmentDoc}
${PageBasicLinkToFieldsFragmentDoc}
${PageCarLinkToFieldsFragmentDoc}
${PartnerSetFieldsFragmentDoc}
${PartnerFieldsFragmentDoc}
${PageCategoryLinkToFieldsFragmentDoc}
${PageDriverLinkToFieldsFragmentDoc}
${PageHomepageLinkToFieldsFragmentDoc}
${PageRaceEventFieldsFragmentDoc}
${PageRaceEventLinkToFieldsFragmentDoc}
${PageTeamLinkToFieldsFragmentDoc}
${TeamFieldsFragmentDoc}`;

export const useModuleMediaFeatureQuery = <TData = ModuleMediaFeatureQuery, TError = unknown>(
    variables: ModuleMediaFeatureQueryVariables,
    options?: Omit<UseQueryOptions<ModuleMediaFeatureQuery, TError, TData>, "queryKey"> & {
        queryKey?: UseQueryOptions<ModuleMediaFeatureQuery, TError, TData>["queryKey"];
    }
) => {
    return useQuery<ModuleMediaFeatureQuery, TError, TData>({
        queryKey: ["ModuleMediaFeature", variables],
        queryFn: customFetcher<ModuleMediaFeatureQuery, ModuleMediaFeatureQueryVariables>(
            ModuleMediaFeatureDocument,
            variables
        ),
        ...options,
    });
};

useModuleMediaFeatureQuery.getKey = (variables: ModuleMediaFeatureQueryVariables) => [
    "ModuleMediaFeature",
    variables,
];

useModuleMediaFeatureQuery.fetcher = (
    variables: ModuleMediaFeatureQueryVariables,
    options?: RequestInit["headers"]
) =>
    customFetcher<ModuleMediaFeatureQuery, ModuleMediaFeatureQueryVariables>(
        ModuleMediaFeatureDocument,
        variables,
        options
    );
